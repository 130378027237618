<template>
  <div>
    <section class="cover-video">
      <video ref="videoRef" class="hidden-sm-down hidden-print hidden-xs" id="cover-video" loop muted="muted"
             poster="../assets/images/transparent-pixel.png">
        <source type="video/mp4" src="https://file.usail.es/static/video/cover.mp4">
        <source type="video/webm" src="https://file.usail.es/static/video/cover.webm">
        <source type="video/ogg" src="https://file.usail.es/static/video/cover.ogv">
      </video>
      <div class="video-cover-overlay">
        <div class="video-cover-caption text-center">
          <b-container>
            <h1 class="display-3">{{ $t('message.home.video_title') }}</h1>
            <h2>{{ $t('message.home.video_subtitle') }}
            </h2>
            <a class="btn btn-lg btn-primary" href="#main" v-scroll-to="'#main'">
              {{ $t('message.home.info_button') }}
            </a>
          </b-container>
        </div>
      </div>
      <a class="video-cover-scroll" href="#main" v-scroll-to="'#main'" aria-label="Scroll down">
        <span class="fa fa-3x fa-angle-down"></span>
        <span class="fa fa-3x fa-angle-down"></span>
        <span class="fa fa-3x fa-angle-down"></span>
      </a>
    </section>

    <section id="main" class="section bg-black-syntelix text-white">
      <b-container>
        <h2 class="text-center text-orange-syntelix">
          {{ $t('message.home.what_do_we_do.title') }}
        </h2>
        <hr class="hr-gradient-orange">
        <p>{{ $t('message.home.what_do_we_do.paragraph_1') }}</p>
        <p>{{ $t('message.home.what_do_we_do.paragraph_2') }}</p>
      </b-container>
    </section>

    <section class="section text-center">
      <b-container>
        <b-row>
          <b-col xs="12" sm="6" md="3">
            <span class="icon-circle">
              <span class="li-lightbulb text-muted"></span>
            </span>
            <h4 class="icon-circle-caption">{{ $t('message.home.quality_oriented.title') }}</h4>
            <p>{{ $t('message.home.quality_oriented.paragraph') }}</p>
          </b-col>
          <b-col xs="12" sm="6" md="3">
            <span class="icon-circle"><span class="li-briefcase text-muted"></span></span>
            <h4 class="icon-circle-caption">{{ $t('message.home.customer_centric.title') }}</h4>
            <p>{{ $t('message.home.customer_centric.paragraph') }}</p>
          </b-col>
          <b-col xs="12" sm="6" md="3">
            <span class="icon-circle"><span class="li-genius text-muted"></span></span>
            <h4 class="icon-circle-caption">{{ $t('message.home.passionates.title') }}</h4>
            <p>{{ $t('message.home.passionates.paragraph') }}</p>
          </b-col>
          <b-col xs="12" sm="6" md="3">
            <span class="icon-circle"><span class="li-global text-muted"></span></span>
            <h4 class="icon-circle-caption">{{ $t('message.home.global_thinking.title') }}</h4>
            <p>{{ $t('message.home.global_thinking.paragraph') }}</p>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="section bg-gray-alt">
      <div class="container">
        <h2 class="text-center">{{ $t('message.home.our_services.title') }}</h2>
        <hr class="hr-gradient">
        <div v-html="$t('message.home.our_services.paragraph')"></div>
        <div class="list-dotted mt-5">
          <b-row no-gutters>
            <b-col xs="12" md="6">
              <router-link class="list-dotted-item list-dotted-item-action" :to="{ name: $i18n.locale + '_services_satellite_broadband_internet' }">
                <span class="icon-circle">
                  <span class="li-global text-muted"></span>
                </span>
                {{ $t('message.services.satellite_broadband_internet.title') }}
              </router-link>
            </b-col>
            <b-col xs="12" md="6">
              <router-link class="list-dotted-item list-dotted-item-action" :to="{ name: $i18n.locale + '_services_vpn_over_satellite' }">
                <span class="icon-circle">
                  <span class="li-lock text-muted"></span>
                </span>
                {{ $t('message.services.vpn_over_satellite.title') }}
              </router-link>
            </b-col>
            <b-col xs="12" md="6">
              <router-link class="list-dotted-item list-dotted-item-action" :to="{ name: $i18n.locale + '_services_telemetry_and_m2m_over_satellite' }">
                <span class="icon-circle">
                  <span class="li-speedometer text-muted"></span>
                </span>
                {{ $t('message.services.telemetry_and_m2m_over_satellite.title') }}
              </router-link>
            </b-col>
            <b-col xs="12" md="6">
              <router-link class="list-dotted-item list-dotted-item-action" :to="{ name: $i18n.locale + '_services_maritime_satellite_internet' }">
                <span class="icon-circle">
                  <span class="li-anchor text-muted"></span>
                </span>
                {{ $t('message.services.maritime_satellite_internet.title') }}
              </router-link>
            </b-col>
            <b-col xs="12" md="6">
              <router-link class="list-dotted-item list-dotted-item-action" :to="{ name: $i18n.locale + '_services_occasional_internet_and_ip_streaming' }">
                <span class="icon-circle">
                  <span class="li-clock text-muted"></span>
                </span>
                {{ $t('message.services.occasional_internet_and_ip_streaming.title') }}
              </router-link>
            </b-col>
            <b-col xs="12" md="6">
              <router-link class="list-dotted-item list-dotted-item-action" :to="{ name: $i18n.locale + '_services_corporate_networks_over_satellite' }">
                <span class="icon-circle">
                  <span class="li-briefcase text-muted"></span>
                </span>
                {{ $t('message.services.corporate_networks_over_satellite.title') }}
              </router-link>
            </b-col>
            <b-col xs="12" md="6">
              <router-link class="list-dotted-item list-dotted-item-action" :to="{ name: $i18n.locale + '_services_mss_inmarsat_and_iridium' }">
                <span class="icon-circle">
                  <span class="li-genius text-muted"></span>
                </span>
                {{ $t('message.services.mss_inmarsat_and_iridium.title') }}
              </router-link>
            </b-col>
            <b-col xs="12" md="6">
              <router-link class="list-dotted-item list-dotted-item-action" :to="{ name: $i18n.locale + '_services_systems_integration' }">
                <span class="icon-circle">
                  <span class="li-gears text-muted"></span>
                </span>
                {{ $t('message.services.systems_integration.title') }}
              </router-link>
            </b-col>
          </b-row>
        </div>
      </div>
    </section>

    <QuickContact />
  </div>

</template>

<script>
  import '../scss/home/index.scss'
  import QuickContact from "../components/QuickContact";

  export default {
    name: 'home',
    components: {
      QuickContact
    },
     metaInfo () {
      return  {
        htmlAttrs: {
          lang: this.$i18n.locale
        },
        title: this.$t('meta.home.title'),
        link: [
          { rel: 'canonical', href: 'https://www.syntelix.net' + this.$route.path.replace(/\/$/, "") }
        ],
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: this.$t('meta.home.description')
          },
          {
            vmid: 'og:title',
            property: 'og:title',
            content: this.$t('meta.home.title')
          },
          {
            vmid: 'og:description',
            property: 'og:description',
            content: this.$t('meta.home.description')
          },
          {
            vmid: 'og:type',
            property: 'og:type',
            content: 'website'
          },
          {
            vmid: 'og:image',
            property: 'og:image',
            content: 'https://syntelix-website.s3-eu-west-1.amazonaws.com/og.jpg'
          },
          {
            vmid: 'og:url',
            property: 'og:url',
            content: 'https://www.syntelix.net' + this.$route.path
          }
        ],
        script: [{
          type: 'application/ld+json',
          json: {
            "@context": "http://schema.org",
            "@type": "Corporation",
            "legalName": "Syntelix Avances Tecnológicos S.L.",
            "brand": "Syntelix",
            "name": "Syntelix",
            "description": this.$t('meta.home.description'),
            "url": "https://www.syntelix.net",
            "email": "info@syntelix.net",
            "faxNumber": "(+34) 986 064 336",
            "logo": 'https://www.syntelix.net' + require('@/assets/images/logo.png'),
            "telephone": "(+34) 911 938 062",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "PTL Valladares Calle C, Nave C4",
              "addressLocality": "Vigo",
              "addressCountry": "España",
              "postalCode": "36314"
            }
          }
        }]
      }
    },

    mounted: function () {
      if (!this.isMobile()) {
        this.$refs.videoRef.play()
      }

    },

    methods: {
      isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      }
    },
  }
</script>
