<template>
    <section class="section section-bg-image text-center text-white"
             :style="'background-image: url(' + require('@/assets/images/small-contact/bg.jpg') + ')'">
        <div class="section-bg-image-overlay"></div>
        <div class="container position-relative">
            <h2>{{ $t('message.quick_contact.title') }}</h2>
            <hr class="hr-gradient-inverse">

            <i18n path="message.quick_contact.subtitle" tag="p" class="lead">
                <router-link :to="{ name: $i18n.locale + '_contact' }" class="link-inverse">{{
                    $t('message.quick_contact.link') }}
                </router-link>
            </i18n>

            <div v-if="emailError" class="email-error d-flex justify-content-center align-items-center">
                <div class="text-center">
                    <div class="li-caution text-danger fa-5x mb-2"></div>
                    <p>{{ $t('message.contact.error') }}</p>
                    <b-btn variant="primary" size="sm" @click="emailError = false">{{ $t('message.contact.try_again') }}</b-btn>
                </div>
            </div>
            <div v-else-if="emailSuccess" class="email-success d-flex justify-content-center align-items-center">
                <div class="text-center">
                    <div class="li-envelope text-success fa-5x mb-2"></div>
                    <p>{{ $t('message.contact.success') }}</p>
                    <b-btn variant="primary" size="sm" @click="emailSuccess = false">{{ $t('message.contact.send_another_request') }}</b-btn>
                </div>
            </div>

            <b-form v-else inline class="small-contact-form" @submit="onSubmit">
                <label class="sr-only" for="name">{{ $t('message.quick_contact.your_name') }}</label>
                <b-input
                        v-model="form.name"
                        id="name"
                        name="name"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        :placeholder="$t('message.quick_contact.your_name')"
                        size="lg"
                ></b-input>

                <label class="sr-only" for="phone">{{ $t('message.quick_contact.your_phone') }}</label>
                <b-input
                        :state="false === phoneBlured ? null : phoneValid"
                        @blur="phoneBlured = true"
                        v-model="form.phone"
                        id="phone"
                        name="phone"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        :placeholder="$t('message.quick_contact.your_phone')"
                        size="lg"
                ></b-input>

                <label class="sr-only" for="country">{{ $t('message.quick_contact.country') }}</label>
                <b-form-select
                        :state="false === countryBlured ? null : countryValid"
                        @change="countryBlured = true"
                        v-model="form.country"
                        id="country"
                        name="country"
                        :options="countryOptions"
                        size="lg">
                </b-form-select>


                <label class="sr-only" for="country">{{ $t('message.quick_contact.what_do_you_need') }}</label>
                <b-form-select
                        :state="false === needBlured ? null : needValid"
                        @change="needBlured = true"
                        v-model="form.need"
                        id="need"
                        name="need"
                        :options="needOptions"
                        size="lg">
                </b-form-select>

                <b-button type="submit" variant="primary" size="lg" :disabled="isLoading || !phoneValid || !countryValid || !needValid">{{ $t('message.quick_contact.call_me') }}</b-button>
            </b-form>
        </div>
    </section>
</template>
<script>
    import Email from '@/assets/js/smtp'
    import countries from '@/coverage.json'

    export default {
        name: 'QuickContact',
        data() {
            return {
                isLoading: false,
                email: Email,
                emailError: false,
                emailSuccess: false,
                form: {
                    name: '',
                    phone: '',
                    country: null,
                    need: null
                },
                phoneBlured: false,
                needBlured: false,
                countryBlured: false,
                countries: countries
            }
        },
        computed: {
            needOptions() {
                return [
                    { value: null, text: this.$t('message.quick_contact.what_do_you_need') },
                    { value: 'Internet', text: this.$t('message.quick_contact.internet') },
                    { value: 'VPN', text: this.$t('message.quick_contact.vpn') },
                    { value: 'Private Network', text: this.$t('message.quick_contact.private_network') },
                    { value: 'Maritime', text: this.$t('message.quick_contact.maritime') },
                    { value: 'Occasional', text: this.$t('message.quick_contact.occasional') },
                    { value: 'MSS', text: this.$t('message.quick_contact.mss') },
                    { value: 'Others', text: this.$t('message.quick_contact.others') },
                ]
            },

            countryOptions() {
                const countryOptions = [
                    { value: null, text: this.$t('message.quick_contact.country') },
                ];
                this.countries[this.$i18n.locale].forEach(country => {
                    countryOptions.push({
                        value: country.name,
                        text: country.name
                    })
                })

                return countryOptions
            },

            phoneValid() {
                return this.form.phone.length > 0;
            },

            needValid() {
                return !!this.form.need;
            },

            countryValid() {
                return !!this.form.country;
            }
        },

        methods: {
            onSubmit(evt) {
                this.isLoading = true
                evt.preventDefault()
                this.email.send({
                    SecureToken : "a9aaf56a-ffca-4441-9969-efc8cda61f2b",
                    To : process.env.VUE_APP_CONTACT_EMAILS,
                    From : "relay@syntelix.net",
                    Subject : "[syntelix.net] New call request",
                    Body : `Name: ${this.form.name}<br/>Phone: ${this.form.phone}<br/>Country: ${this.form.country}<br/>What do you need?: ${this.form.need}<br/>URL: ${window.location}`
                }).then(
                    message => {
                        this.isLoading = false
                        if (message === 'OK') {
                            this.emailSuccess = true
                        } else {
                            this.emailError = true
                        }
                    }
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
    .email-error,
    .email-success {
        min-height: 200px;
    }
</style>

